import C from "../../components/sidebar/StandardSidebar.module.css";

import { datetimeToDate } from "@warrenio/api-spec/conversion";
import { localeCompareBy } from "../../components/data/comparers.ts";
import { getFilteredItems } from "../../components/data/getFilteredItems.ts";
import { getSortedItems, type SimpleSortField } from "../../components/data/getSortedItems.ts";
import { EmptyItem, NoResults } from "../../components/sidebar/EmptyItem.tsx";
import { PriceBlock } from "../../components/sidebar/PriceBlock.tsx";
import { SidebarList, type SidebarListState } from "../../components/sidebar/SidebarList.tsx";
import { StandardBlock } from "../../components/sidebar/StandardSidebar.tsx";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceCreateLinks } from "../api/resourceTypeLinks.ts";
import { leaseLink } from "./links.ts";
import { type MetalListItemType, metalQueryAtom } from "./metalQuery.ts";
import { OsIcon } from "./os/OsIcon.tsx";

const sortFields = [
    {
        id: "name",
        title: "Name",
        compare: localeCompareBy((i) => i.spec.title),
    },
    { id: "created_at", title: "Created Date", getValue: (i) => datetimeToDate(i.created_at).getTime() },
    { id: "location", title: "Location", getValue: (i) => i.location },
    //{ id: "price", title: "Price", getValue: (i) => i.price.hourly },
] satisfies SimpleSortField<MetalListItemType>[];

export default function MetalList() {
    return (
        <SidebarList
            data={(props) => <MetalData {...props} />}
            title="Bare Metal"
            sortFields={sortFields}
            actionButton={{
                title: "Create New Resource",
                action: ResourceCreateLinks.bare_metal,
            }}
            listId="metal"
        />
    );
}

function MetalData({ queryString, sortId, sortAsc }: SidebarListState) {
    const data = useSuspenseQueryAtom(metalQueryAtom);

    let metalItems = getFilteredItems(queryString, [...data.values()], (item) => [item.spec.title]);
    metalItems = getSortedItems(sortFields, { sortId, sortAsc }, metalItems);

    const metalBlocks = metalItems.map((obj) => <MetalBlock key={obj.uuid} item={obj} />);

    const empty = (
        <EmptyItem icon="jp-host-icon">Create a new resource and get your services running in minutes.</EmptyItem>
    );

    return metalBlocks.length ? metalBlocks : queryString ? <NoResults /> : empty;
}

export function MetalBlock({ item }: { item: MetalListItemType }) {
    const { ip_public_v4, display_name, os_id, location } = item;

    const price = { hourly: 0.123 };

    return (
        <StandardBlock link={leaseLink(item)}>
            <div className={C.StandardBlockIcon}>
                <OsIcon os_id={os_id} className="size-0.875rem" />
            </div>

            <div className={cn(C.StandardBlockName, "text-ellipsis")}>
                <b className="text-ellipsis">{display_name}</b>
                <div className="text-ellipsis color-primary">
                    {ip_public_v4} / {location}
                </div>
            </div>

            <PriceBlock price={price} />
        </StandardBlock>
    );
}
